import {stringify} from 'query-string';
import {notFound} from 'next/navigation';
import isObject from './isObject';

const deepStringify = (obj: Object) => {
  const safeQuery = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (isObject(value)) {
      safeQuery[key] = JSON.stringify(value);
    } else {
      safeQuery[key] = value;
    }
  });

  return stringify(safeQuery);
};

const handleErrors = async (response: any, disableRedirect: boolean) => {
  if (!response.ok) {
    if (!disableRedirect && (response.status === 401 || response.status === 403)) {
      if (typeof window !== 'undefined') {
        window.location.href = '/login';
      }
    }

    if (response.status === 404) return notFound();

    let data;

    try {
      data = await response.json();
    } catch (error) {}

    // Error catchers in form submissions are expecting response to read error messages from
    return Promise.reject({res: response, data});
  }

  return response;
};

const isServer = typeof window === 'undefined';

export type FetcherOptions = {
  url: string;
  options?: RequestInit;
  query?: Object;
  body?: any;
  disableRedirect?: boolean;
  authCookie?: any;
};

const fetcher = ({url, options, query, body, disableRedirect, authCookie}: FetcherOptions) => {
  if (process.env.BUILD === 'true') return Promise.resolve(null);

  let finalUrl = isServer ? `${process.env.NEXT_PUBLIC_BASE_URL}${url}` : url;

  if (query) {
    finalUrl += `?${deepStringify(query)}`;
  }

  return fetch(finalUrl, {
    ...options,
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      'Content-Type': 'application/json',
      cookie: authCookie ? `humbo-auth=${authCookie}` : undefined
    }
  })
    .then((res) => handleErrors(res, disableRedirect))
    .then((res) => {
      if (!res.json) return;

      return res.json();
    });
};

export default fetcher;
