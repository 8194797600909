import {ForwardedRef} from 'react';
import {twMerge} from 'tailwind-merge';
import {PressEvent} from 'react-aria-components';
import ButtonComponentWrapper from './ButtonComponentWrapper';
import SmallLoader from './common/SmallLoader';
import RemixIcon from './RemixIcon';

const getVariantClassNames = (variant: string | undefined) => {
  switch (variant) {
    case 'text':
      return 'bg-transparent hover:bg-[--button-bg-hover] pressed:bg-[--button-bg-active] text-[--text-color]';
    case 'secondary':
      return 'secondary-button text-[--text-color]';
    case 'translucent':
      return 'btn-border dark:shadow-[inset_0_0_0_1px_rgb(245_225_255_/_0.12)] backdrop-blur bg-[rgb(255_255_255_/_0.8)] dark:bg-[rgb(255_255_255_/_0.12)] hover:bg-[rgb(255_255_255_/_0.9)] dark:hover:bg-[rgb(255_255_255_/_0.2)] pressed:brightness-[0.98] text-[--text-color]';
    case 'primary':
      return 'primary-button-shadow rounded-full bg-[--purple] hover:brightness-110 pressed:brightness-125 text-white';
    default:
      return '';
  }
};

const getSizeClassNames = (size: string | undefined) => {
  switch (size) {
    case 'large':
      return 'text-xl px-6 h-[48px]';
    case 'small':
      return 'px-4 h-[36px]';
    default:
      return 'px-4 h-[42px]';
  }
};

type Props = {
  children?: React.ReactNode;
  label?: string;
  className?: string;
  icon?: any;
  iconSize?: number;
  color?: string;
  style?: React.CSSProperties;
  buttonVariant?: 'text' | 'primary' | 'secondary' | 'translucent';
  size?: 'large' | 'small';
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  onPress?: (e: PressEvent) => void;
  href?: string;
  scroll?: boolean;
  prefetch?: boolean;
  'aria-label'?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  isLoading?: boolean;
  target?: string;
  tabIndex?: number;
  ref?: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>;
};

const BaseButton = ({
  children,
  label,
  className,
  icon,
  style = {},
  iconSize = 18,
  isLoading,
  onClick,
  onPress,
  color,
  buttonVariant,
  href,
  scroll,
  prefetch,
  type,
  disabled,
  target,
  tabIndex,
  ref,
  size,
  'aria-label': ariaLabel
}: Props) => {
  const variantClassNames = getVariantClassNames(buttonVariant);
  const sizeClassNames = getSizeClassNames(size);

  const buttonStyle = color ? {color, ...style} : style;

  return (
    <ButtonComponentWrapper
      ref={ref}
      href={href}
      target={target}
      onClick={isLoading ? undefined : onClick}
      onPress={isLoading ? undefined : onPress}
      type={type}
      scroll={scroll}
      prefetch={prefetch}
      disabled={disabled}
      tabIndex={tabIndex}
      aria-label={ariaLabel}
      className={twMerge(
        'relative overflow-hidden flex shrink-0 items-center justify-center border-none rounded-full font-semibold py-3 no-underline select-none leading-none ring-offset-1 focus-visible:ring-2 focus:ring-gray-200 pressed:brightness-90 transition duration-100',
        disabled ? 'opacity-75 cursor-not-allowed' : 'cursor-pointer',
        isLoading && '[&>*:not(.loading)]:!opacity-0 !text-transparent',
        variantClassNames,
        sizeClassNames,
        className
      )}
      style={buttonStyle}
    >
      {icon ? (
        <div className={twMerge('icon-wrapper -ml-[2px]', children || label ? 'mr-2' : '-mr-[2px]')}>
          <RemixIcon icon={icon} size={iconSize} color={color || 'currentColor'} />
        </div>
      ) : null}

      {children || label}

      {isLoading ? (
        <SmallLoader
          className="loading absolute"
          color={color || (buttonVariant === 'primary' ? 'white' : undefined)}
        />
      ) : null}
    </ButtonComponentWrapper>
  );
};

export default BaseButton;
