'use client';

import {Button, Link, PressEvent} from 'react-aria-components';

type Props = {
  children: React.ReactNode;
  href?: string;
  scroll?: boolean;
  onPress?: (e: PressEvent) => void;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  ref?: any;
  className?: string;
  style?: React.CSSProperties;
  prefetch?: boolean;
  type?: 'button' | 'submit';
  disabled?: boolean;
  target?: string;
  tabIndex?: number;
};

const ButtonComponentWrapper = (props: Props) => {
  if (props.href) return <Link {...props} />;

  return <Button {...props} />;
};

export default ButtonComponentWrapper;
