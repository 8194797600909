'use client';

import {AnchorHTMLAttributes} from 'react';
import {Link, LinkProps} from 'react-aria-components';

const FastLink = (props: AnchorHTMLAttributes<HTMLAnchorElement> & LinkProps) => {
  return (
    <Link
      {...props}
      // onClick={(e) => {
      //   /*
      //     This strange workaround is needed because if normal Link is used, prefetch happens twice.
      //     When re-route is triggered with router.push, prefetch happens once.
      //   */
      //   if (e.metaKey || e.ctrlKey || e.shiftKey) return;

      //   e.preventDefault();

      //   if (!props.href) return;

      //   if (props.replace) return router.replace(props.href);

      //   router.push(props.href);
      // }}
      // onMouseEnter={(event) => {
      //   if (props.href) router.prefetch(props.href);
      //   if (props.onMouseEnter) props.onMouseEnter(event);
      // }}
    />
  );
};

export default FastLink;
