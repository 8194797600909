export {default as arrowRightLine} from 'remixicon/icons/Arrows/arrow-right-line.svg';

export {default as arrowRightSLine} from 'remixicon/icons/Arrows/arrow-right-s-line.svg';

export {default as pencilLine} from 'remixicon/icons/Design/pencil-line.svg';

export {default as imageEditLine} from 'remixicon/icons/Media/image-edit-line.svg';

export {default as bracesLine} from 'remixicon/icons/Development/braces-line.svg';

export {default as flagLine} from 'remixicon/icons/Business/flag-line.svg';
export {default as flagFill} from 'remixicon/icons/Business/flag-fill.svg';

export {default as mapPinLine} from 'remixicon/icons/Map/map-pin-line.svg';

export {default as map2Line} from 'remixicon/icons/Map/map-2-line.svg';

export {default as starSmileLine} from 'remixicon/icons/User & Faces/star-smile-line.svg';

export {default as search2Line} from 'remixicon/icons/System/search-2-line.svg';

export {default as closeLine} from 'remixicon/icons/System/close-line.svg';

export {default as checkboxBlankCircleLine} from 'remixicon/icons/System/checkbox-blank-circle-line.svg';
export {default as checkboxCircleLine} from 'remixicon/icons/System/checkbox-circle-line.svg';

export {default as bucket} from 'public/icons/bucketIcon.svg';

export {default as arrowLeftLine} from 'remixicon/icons/Arrows/arrow-left-line.svg';

export {default as checkLine} from 'remixicon/icons/System/check-line.svg';

export {default as starFill} from 'remixicon/icons/System/star-fill.svg';

export {default as menu4Line} from 'remixicon/icons/System/menu-4-line.svg';

export {default as timeLine} from 'remixicon/icons/System/time-line.svg';

export {default as historyLine} from 'remixicon/icons/System/history-line.svg';

export {default as thumbUpLine} from 'remixicon/icons/System/thumb-up-line.svg';

export {default as thumbDownLine} from 'remixicon/icons/System/thumb-down-line.svg';

export {default as userSmileLine} from 'remixicon/icons/User & Faces/user-smile-line.svg';

export {default as earthLine} from 'remixicon/icons/Map/earth-line.svg';

export {default as logoutCircleLine} from 'remixicon/icons/System/logout-circle-line.svg';

export {default as loginCircleLine} from 'remixicon/icons/System/login-circle-line.svg';

export {default as settingsLine} from 'remixicon/icons/System/settings-line.svg';

export {default as addCircleLine} from 'remixicon/icons/System/add-circle-line.svg';

export {default as addLine} from 'remixicon/icons/System/add-line.svg';

export {default as checkboxCircleFill} from 'remixicon/icons/System/checkbox-circle-fill.svg';

export {default as moreFill} from 'remixicon/icons/System/more-fill.svg';

export {default as deleteBin3Line} from 'remixicon/icons/System/delete-bin-3-line.svg';

export {default as externalLinkLine} from 'remixicon/icons/System/external-link-line.svg';

export {default as sunLine} from 'remixicon/icons/Weather/sun-line.svg';

export {default as moonLine} from 'remixicon/icons/Weather/moon-line.svg';

export {default as magicLine} from 'remixicon/icons/Design/magic-line.svg';

export {default as imageAddLine} from 'remixicon/icons/Media/image-add-line.svg';

export {default as fileCopyLine} from 'remixicon/icons/Document/file-copy-line.svg';

export {default as seedlingFill} from 'remixicon/icons/Others/seedling-fill.svg';

export {default as fileEditLine} from 'remixicon/icons/Document/file-edit-line.svg';

export {default as alignLeft} from 'remixicon/icons/Editor/align-left.svg';

export {default as ratingStar} from 'public/icons/ratingStar.svg';

export {default as anchorLine} from 'remixicon/icons/Map/anchor-line.svg';

export {default as building2Fill} from 'remixicon/icons/Buildings/building-2-fill.svg';

export {default as treeFill} from 'public/icons/treeIcon.svg';

export {default as otherPlace} from 'public/icons/otherPlaceIcon.svg';

export {default as buildingFill} from 'remixicon/icons/Buildings/building-fill.svg';

export {default as village} from 'public/icons/villageIcon.svg';

export {default as peak} from 'public/icons/peakIcon.svg';

export {default as articleLine} from 'remixicon/icons/Document/article-line.svg';

export {default as trophyLine} from 'remixicon/icons/Finance/trophy-line.svg';

export {default as arrowLeftSLine} from 'remixicon/icons/Arrows/arrow-left-s-line.svg';

export {default as dropLine} from 'remixicon/icons/Design/drop-line.svg';
export {default as dropFill} from 'remixicon/icons/Design/drop-fill.svg';

export {default as instagramLine} from 'remixicon/icons/Logos/instagram-line.svg';

export {default as twitterXLine} from 'remixicon/icons/Logos/twitter-x-line.svg';

export {default as smartphoneLine} from 'remixicon/icons/Device/smartphone-line.svg';

export {default as arrowDownSLine} from 'remixicon/icons/Arrows/arrow-down-s-line.svg';

export {default as gameLine} from 'remixicon/icons/Others/game-line.svg';

export {default as bardFill} from 'remixicon/icons/Logos/bard-fill.svg';

export {default as arrowRightUpLine} from 'remixicon/icons/Arrows/arrow-right-up-line.svg';

export {default as subwayFill} from 'remixicon/icons/Map/subway-fill.svg';

export {default as compassLine} from 'remixicon/icons/Map/compass-line.svg';

export {default as skipDownLine} from 'remixicon/icons/Arrows/skip-down-line.svg';
export {default as skipUpLine} from 'remixicon/icons/Arrows/skip-up-line.svg';

export {default as infoI} from 'remixicon/icons/Editor/info-i.svg';

export {default as share2Line} from 'remixicon/icons/System/share-2-line.svg';

export {default as signpostFill} from 'remixicon/icons/Map/signpost-fill.svg';

export {default as functionLine} from 'remixicon/icons/System/function-line.svg';

export {default as roadMapFill} from 'remixicon/icons/Map/road-map-fill.svg';

export {default as ridingLine} from 'remixicon/icons/Map/riding-line.svg';

export {default as treeLine} from 'remixicon/icons/Others/tree-line.svg';

export {default as sparkling2Line} from 'remixicon/icons/Weather/sparkling-2-line.svg';
